@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap?crossorigin=anonymous');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@400;500;600;700&display=swap?crossorigin=anonymous');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600;700;800&display=swap');


.h-100{
    height:36rem;
}

.h-99{
    height:35rem;
}

::-webkit-scrollbar {
  /* width: 8px; */
  display: none;
}

Track
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:  #ccccff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:  #b3b3ff;
}